import React, { useState ,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Sidebar from './Sidebar';
import { baseURL } from '../../config/baseURLPath';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './commoncss.css';
const DashboardHeader = () => {
  const navigate = useNavigate();
  const [loggedIn] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('lj_token') },
  };
  useEffect(() => {
    const interval = setInterval(() => {
         localStorage.removeItem('user');
          localStorage.removeItem('user_id');
          localStorage.removeItem('loginid');
          localStorage.removeItem('profile');
          localStorage.removeItem('lj_token');
          localStorage.removeItem('token');
          window.sessionStorage.clear();
          navigate('/');
    }, 1800000);
    return () => clearInterval(interval);
  });
  useEffect(() => {
    if(localStorage.getItem('user_id') != null){
    getMyNotifications();
    }else{
      // navigate('/');
    }
  },[]);
  useEffect(() => {
    const intervalCall = setInterval(() => {
      getMyNotifications();
    }, 300000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  },[]);
  const getMyNotifications = () => {
    axios
      .get(
        baseURL +
          'getAllUnreadNotificationByUserId/' +
          localStorage.getItem('user_id'),
        jwtHeader
      )
      .then((response) => {
        let my_notifications = response.data.Response.concat(response.data.EventNotifications)
        let merge_more = my_notifications.concat(response.data.PostNotifications);
        setNotifications(merge_more);
      })
      .catch((error) => {});
  };
  return (
    <div className="fixHeader">
      <Row style={{ margin: 0 }}>
        <Col md={4} lg={4} className="leftMenuSection header-social">
          <a href="https://www.facebook.com/groups/lovejustice" target='_blank' rel="noreferrer"> 
            <img
              className="logo-icon"
              alt="Responsive"
              src="../assets/Icon/Facebbok-Header.svg"
            />
          </a>
          <a href="https://twitter.com/LoveJusticeTeam" target='_blank' rel="noreferrer">
            <img
              className="logo-icon"
              alt="Responsive "
              src="../assets/Icon/Twitter-header.svg"
            />
          </a>

          <a href="https://www.instagram.com/lovejusticeteam" target='_blank' rel="noreferrer">
            <img
                className="logo-icon-insta"
                alt="Responsive "
                src="../assets/Icon/Instagram.svg"
            />
          </a>
        </Col>
        <Col md={4} sm={3} lg={4} className="centerMenuSection">
          <a href="https://www.lovejustice.com/">
            <img
              className="mid_pic img-fluid"
              alt="Responsive "
              src="https://love-justice-images.s3.us-east-2.amazonaws.com/app-logo.png"
              style={{maxHeight:"96px"}}
            />
          </a>
        </Col>
        <Col md={8} sm={9} lg={4} className="rightMenuSection">
          <Link to="/volunteer" className="donateLink volunteer">
            VOLUNTEER
          </Link>
          {loggedIn ? 
          <Link to="/donate/user-details" className="donateLink">
            DONATE
          </Link> : null }
          {localStorage.getItem('user') !==null ? (
            <div>
            <Link to="/notifications">
              <img
                className="logo-icon"
                alt="Responsive "
                src="../assets/Icon/notification.svg"
              /> 
            </Link>
            <span class="counter counter-lg" style={{borderRadius:'100%',height: '25px',width: '20px',backgroundColor: '#f48634',color: '#ffffff',textAlign: 'center',position: 'relative',top: '-9px',float: 'right',right: '30px'}}>{notifications.length}</span>&nbsp;&nbsp;
            </div>
          ) : null}
          <Sidebar />
        </Col>
      </Row>
    </div>
  );
};

export default DashboardHeader;
