import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { baseURL } from '../config/baseURLPath';
import './registration.css';
import axios from 'axios';

const VerifiedSuccessfully = (props) => {
  const navigate = useNavigate();
//   const location = useLocation();
  const params = useParams();
  const [status, setStatus] = useState(0)
//   console.log(params);  
  useEffect(() => {
    if(params.id === undefined || params.id === ''){
        navigate('/')
    }else{
        verifyEmail(params.id)
    }
    //eslint-disable-next-line
  }, []);

  const verifyEmail = (id) => {
    axios({
      method: 'GET',
      url: baseURL + 'auth/verifyEmailAddress/'+id
    }).then((res) => {
      if(res.data.Success === 200){
        setStatus(1)
      }else{
        setStatus(2)
      }
    });
  };

  return (
    <>
      <div>
        {status === 1 ?  
        <Row style={{ margin: 0, minHeight:"60vh" }}>
          <Col md={4}></Col>
          <Col md={4} className="content-box" style={{ marginTop: '20px', display:"flex", alignItem:"center", justifyContent:"center"}}>
            <Row>
              <Col md={12} style={{display:"flex", alignItems:'center'}}>
                <div style={{width:"100%"}}>  
                    <p className="headingText" style={{ textAlign: 'center', color:"#4caf50", fontSize:"30px", fontWeight:"bold", marginBottom:"50px"}}>
                    Congratulations 
                    </p>  
                    <p className="headingText" style={{ textAlign: 'center' }}>
                    Your email has been verified successfully.
                    </p>
                    <p className="headingText" style={{ textAlign: 'center' }}>
                    Click here to <a style={{color:"#0066ff"}} href="/" onClick={() => navigate('/')}>Login</a>
                    </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={4}></Col>
        </Row> : null }
        {status === 2 ?  
        <Row style={{ margin: 0, minHeight:"60vh" }}>
          <Col md={4}></Col>
          <Col md={4} className="content-box" style={{ marginTop: '20px', display:"flex", alignItem:"center", justifyContent:"center"}}>
            <Row>
              <Col md={12} style={{display:"flex", alignItems:'center'}}>
                <div style={{width:"100%"}}>  
                    <p className="headingText" style={{ textAlign: 'center', color:"#ff6666", fontSize:"30px", fontWeight:"bold", marginBottom:"50px"}}>
                    Failed 
                    </p>  
                    <p className="headingText" style={{ textAlign: 'center' }}>
                    Email verification failed.
                    </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={4}></Col>
        </Row> : null }
        {status === 0 ?  
        <Row style={{ margin: 0, minHeight:"60vh" }}>
          <Col md={4}></Col>
          <Col md={4} className="content-box" style={{ marginTop: '20px', display:"flex", alignItem:"center", justifyContent:"center"}}>
            <Row>
              <Col md={12} style={{display:"flex", alignItems:'center'}}>
                <div style={{width:"100%"}} >  
                    <p className="headingText" style={{ textAlign: 'center' }}>
                    Verifying, please wait.
                    </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={4}></Col>
        </Row> : null }
      </div>
    </>
  );
};

export default VerifiedSuccessfully;
