import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Row, Col, Button, InputGroup } from 'react-bootstrap';
import { baseURL } from '../config/baseURLPath';
import './registration.css';
import axios from 'axios';
import queryString from 'query-string';
const AddEventInCalendar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [success, setSuccess] = useState(false);  
  const [event, setEvent] = useState(0);
    
  const addEventInCalendar = (email, event) => {
    // Send a POST request
    axios({
        method: 'post',
        url: baseURL + 'addAdminEventUser',
        data: { user_email: email, event_id:event },
    })
    .then((response) => {
        
    })
    .catch((error) => {
        
    });
  };


  useEffect(() => {
    const value = queryString.parse(location.search);
    const event_id = value?.event_id;
    if(event_id !== null && event_id !== undefined){
        setEvent(event_id)
        if (localStorage.getItem('user_id') !== null) {
            // get user details
            let user_details = localStorage.getItem('user');
            if(user_details !== null){
                user_details = JSON.parse(user_details);
                addEventInCalendar(user_details.user_email, event_id)
            }
            setSuccess(true)
        }else{
            setSuccess(false)
        }
    }else{
        navigate('/')
    }
  }, []);

  
  return (
    <>
      <div>
        <Row style={{ margin: 0 }}>
          <Col md={4}></Col>
          <Col md={4} className="content-box" style={{ marginTop: '20px' }}>
            {success ?   
            <Row style={{minHeight:"50vh", display:"flex", alignItems:'center'}}>
              <Col md={12} style={{display:"flex", flexDirection:"column", alignItems:'center'}}>
                <img src="./assets/vector/check_complete_done_green_success_icon.png" className="successImg"/>  
                <p className="successMsgText" style={{ textAlign: 'center' }}>
                  CONGRATULATIONS
                </p>
                <p className="successMessageText" style={{ textAlign: 'center' }}>
                  Event successfully added in your calendar.
                </p>
                <Row className="actionButtonDiv">
                    <Button className="acceptButton" onClick={() => navigate('/dashboard')}>
                      GO TO DASHBOARD
                    </Button>
                  </Row>
              </Col>
            </Row> :
            <Row style={{minHeight:"50vh", display:"flex", alignItems:'center'}}>
                <Col md={12} style={{display:"flex", flexDirection:"column", alignItems:'center'}}>
                <img src="./assets/vector/failed.png" className="successImg"/>  
                <p className="successMsgText" style={{ textAlign: 'center' }}>
                    OOPS!
                </p>
                <p className="successMessageText" style={{ textAlign: 'center' }}>
                    Seems you are not logged in. <br/>If you have an account, please login to continue.
                </p>
                <Row className="actionButtonDiv">
                    <Button className="acceptButton" onClick={() => navigate('/?event_id='+event)}>
                        LOGIN
                    </Button>
                </Row>
                <p className="successMessageText" style={{ textAlign: 'center' }}>
                    Or create an account and try again. 
                </p>
                <span>
                    <Link
                        to="/become_a_member/member_pledge"
                        className="links"
                    >
                        {' '}
                        Become a member
                    </Link>
                </span>
                </Col>
            </Row> }

          </Col>
          <Col md={4}></Col>
        </Row>
      </div>
    </>
  );
};

export default AddEventInCalendar;
