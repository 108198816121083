import React, {useEffect, useState} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {Col, Container, Form, Row} from 'react-bootstrap'
import {baseURL} from '../config/baseURLPath'
import axios from 'axios'
import {CSVLink} from 'react-csv'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from "moment";

const Dashboard = (props) => {
  const [report, setReport] = useState([])
  useEffect(() => {
    usageReport()
    //eslint-disable-next-line
  }, [])
  const usageReport = () => {
    axios({
      method: 'GET',
      url: baseURL + 'getAdminDashboardData',
    })
        .then((response) => {
          console.log(response)
          setReport(response.data.Response)
        })
        .catch((error) => {
          console.log(error)
        })
  }

  const pdf = () => {
    const unit = 'pt'
    const size = 'A4'
    const orientation = 'portrait'

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)
    doc.setFontSize(15)

    const title = 'Usage Report'
    const headers = [['S.no', 'Month', 'Donation to Cause', 'Donation to LoveJustice', 'Overall Events', 'Overall Total Users', 'Overall Total Volunteered Hours']]
    const data = report.map((ele, index) => [index + 1, ele.month, ele.cause_donation,
      ele.lj_donation, ele.overall_events, ele.overall_total_users, ele.overall_total_volunteered_hours])
    let content = {
      startY: 50,
      head: headers,
      body: data,
    }
    doc.text(title, marginLeft, 40)
    doc.autoTable(content)
    doc.save('UsageReport.pdf')
  }

  return (
      <div className="contentBoxAdmin">
        <Container>
          <Row>
            <Col md={12} className="mt-4">
              <CSVLink
                  filename={'UsageReport.csv'}
                  color="primary"
                  style={{
                    float: 'right',
                    marginRight: '10px',
                    backgroundColor: '#c26a00',
                    color: "#ffffff",
                    border: "none"
                  }}
                  className="btn btn-primary"
                  data={report}
              >
                Download CSV
              </CSVLink>
              <button
                  onClick={pdf}
                  style={{
                    float: 'right',
                    marginRight: '10px',
                    backgroundColor: '#c26a00', color: "#ffffff", border: "none"
                  }}
                  className="btn btn-primary"
                  data={report}
              >
                Download PDF
              </button>
            </Col>
          </Row>
        </Container>
        <br/>

        <BarGraph data={report}/>
      </div>
  )
}

export default Dashboard;

function BarGraph(props) {
  const data = props.data
  // Let's make data
  let month = []
  let cause_donation = []
  let lj_donation = []
  let overall_events = []
  let overall_total_users = []
  let overall_total_volunteered_hours = []

  for (let i = 0; i < data.length; i++) {

    month.push(data[i].month)
    cause_donation.push(parseInt(data[i].cause_donation))
    lj_donation.push(parseInt(data[i].lj_donation))
    overall_events.push(parseInt(data[i].overall_events))
    overall_total_users.push(parseInt(data[i].overall_total_users))
    overall_total_volunteered_hours.push(parseInt(data[i].overall_total_volunteered_hours))

  }

  const options = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Yearly Analytics',
    },
    xAxis: {
      categories: month,
      title: {
        text: null,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: 'In Nos',
        align: 'center',
      },
      labels: {
        overflow: 'justify',
      },
    },
    tooltip: {
      valueSuffix: ' No(s)',
    },
    plotOptions: {
      series: {
        pointPadding: 0.3,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: '{point.y:.f}',
        },
      },
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      x: 0,
      y: 0,
      floating: true,
      borderWidth: 1,
      backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
      shadow: true,
    },
    credits: {
      enabled: false,
    },

    series: [
      {
        name: 'Donation to Justice Cause',
        data: cause_donation
      },
      {
        name: 'Donation to LoveJustice Mission',
        data: lj_donation
      },
      {
        name: 'Overall Events',
        data: overall_events
      },
      {
        name: 'Overall Total Users',
        data: overall_total_users
      },
      {
        name: 'Overall Total Volunteered Hours',
        data: overall_total_volunteered_hours
      }
    ],
  }
  return <HighchartsReact highcharts={Highcharts} options={options}/>
}
