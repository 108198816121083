import React, { useEffect } from 'react';
import axios from 'axios';
import { baseURL } from '../config/baseURLPath';
import { useNavigate } from 'react-router-dom';
const Logout = (props) => {
  useEffect(() => {
    logout();
    // eslint-disable-next-line
  }, []);
  const navigate = useNavigate();

  const logout = async () => {
    let loginid = JSON.parse(localStorage.getItem('loginid'));
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('lj_token') },
    };
    await axios
      .post(
        baseURL + 'setUserLogout',
        {
          login_id: loginid,
        },
        jwtHeader
      )
      .then(function (response) {
        if (response.data) {
          localStorage.removeItem('user');
          localStorage.removeItem('user_id');
          localStorage.removeItem('loginid');
          localStorage.removeItem('profile');
          localStorage.removeItem('lj_token');
          localStorage.removeItem('token');
          window.sessionStorage.clear();
          navigate('/');
        }
      })
      .catch(function (error) {
        console.log('error' + error);
        localStorage.removeItem('user');
        localStorage.removeItem('user_id');
        localStorage.removeItem('loginid');
        localStorage.removeItem('profile');
          localStorage.removeItem('lj_token');
          localStorage.removeItem('token');
        window.sessionStorage.clear();
        navigate('/');
      });
  };

  return (
    <div className="container m-5">
      <h3 style={{ color: '#000' }}>Logging you out...</h3>
    </div>
  );
};
export default Logout;
