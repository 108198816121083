import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import './admincss.css'
import axios from 'axios';
import { baseURL } from '../../config/baseURLPath';
const Header = () => {
  const navigate = useNavigate()
  const logout = async (e) => {
    let loginid = JSON.parse(localStorage.getItem('user_id'));
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('lj_token') },
    };
    await axios
      .post(
        baseURL + 'adminLogout',
        {
          admin_id: loginid,
        },
        jwtHeader
      )
      .then(function (response) {
        if (response.data) {
          localStorage.removeItem('user');
          localStorage.removeItem('user_id');
          localStorage.removeItem('lj_token');
          window.sessionStorage.clear();
          navigate('/admin');
        }
      })
      .catch(function (error) {
        console.log('error' + error);
        localStorage.removeItem('user');
        localStorage.removeItem('user_id');
        localStorage.removeItem('lj_token');
        window.sessionStorage.clear();
        navigate('/admin');
      });
  };
  return (
    <div className="fixHeaderAdmin">
      <Row style={{ margin: 0 }}>
        <Col md={6} className="d-flex align-items-center justify-content-start">
          <p style={{ color: '#fff', margin: 0 }}>Admin Dashboard</p>
        </Col>
        <Col md={4}>
          <img className="mid_pic_admin img-fluid" alt="Responsive " src="../assets/Logo.png" style={{maxHeight:"96px"}} />
        </Col>
        <Col md={2} className="rightMenuSection">
          <button
          style={{border: 'none', background: 'none'}}
          type="button"
            onClick={(e) => {
              logout(e)
            }}
            className="logoutLink"
          >
            Logout
          </button>
        </Col>
      </Row>
    </div>
  )
}

export default Header
