import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Sidebar from './Sidebar';
import './commoncss.css';
import {Link} from 'react-router-dom';

const Header = () => {

  return (
      <div className="fixHeader">
        <Row style={{margin: 0}}>
          <Col md={4} lg={4} className="leftMenuSection header-social">
            <a href="https://www.facebook.com/groups/lovejustice" target='_blank' rel="noreferrer">
              <img
                  className="logo-icon"
                  alt="Responsive"
                  src="../assets/Icon/Facebbok-Header.svg"
              />
            </a>
            <a href="https://twitter.com/LoveJusticeTeam" target='_blank' rel="noreferrer">
              <img
                  className="logo-icon"
                  alt="Responsive "
                  src="../assets/Icon/Twitter-header.svg"
              />
            </a>
            <a href="https://www.instagram.com/lovejusticeteam" target='_blank' rel="noreferrer">
              <img
                  className="logo-icon-insta"
                  alt="Responsive "
                  src="../assets/Icon/Instagram.svg"
              />
            </a>

          </Col>
          <Col md={4} sm={3} lg={4} className="centerMenuSection">
            <a href="https://www.lovejustice.com/">
              <img
                  className="mid_pic img-fluid"
                  alt="Responsive "
                  src="https://love-justice-images.s3.us-east-2.amazonaws.com/app-logo.png"
                  style={{maxHeight: "96px"}}
              />
            </a>
          </Col>
          <Col md={8} sm={9} lg={4} className="rightMenuSection">

            <Link to="/volunteer" className="donateLink volunteer">
              VOLUNTEER
            </Link>
            <Link to="/donate/user-details" className="donateLink">
              DONATE
            </Link>

            {/* {loggedIn ? (
            <Link to="/notifications">
              <img
                className="logo-icon"
                alt="Responsive "
                src="../assets/Icon/notification.svg"
              />
            </Link>
          ) : null} */}
          <Sidebar />
        </Col>
      </Row>
    </div>
  );
};

export default Header;
