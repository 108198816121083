import { Routes, Route } from 'react-router-dom'
import React, { lazy, Suspense } from 'react'
import Login from './adminLogin'
import Header from './common/Header'
import SideBar from './common/SideBar'
import Dashboard from './Dashboard'

const CharityDonationReport = lazy(() => import('./pages/reports/CharityDonationReport'))
const AddAdminEvent = lazy(() => import('./pages/events/Add'))
const ListAdminEvent = lazy(() => import('./pages/events/List'))
const EditAdminEvent = lazy(() => import('./pages/events/Edit'))
const AddNewletter = lazy(() => import('./pages/newsletter/Add'))
const ListNewsLetter = lazy(() => import('./pages/newsletter/List'))
const ListNewsLetterMembers = lazy(() => import('./pages/newsletter/NewsLetterMembers'))

// const ListBlogMembers = lazy(() => import('./pages/blogs/BlogMembers'))
const ListBlogMembersWordpress = lazy(() => import('./pages/blogs/BlogMembersWordpress'))
const ListBlog = lazy(() => import('./pages/blogs/BlogList'))

const EditNewletter = lazy(() => import('./pages/newsletter/Edit'))
const PreviewNewsletter = lazy(() => import('./pages/newsletter/Preview'))
const ListJusticeCause = lazy(() => import('./pages/justicecause/List'))
const AddJusticeCause = lazy(() => import('./pages/justicecause/Add'))
const EditJusticeCause = lazy(() => import('./pages/justicecause/Edit'))

// Admin
const ListAdmin = lazy(() => import('./pages/mange-admin/List'))
const AddAdmin = lazy(() => import('./pages/mange-admin/Add'))
const EditAdmin = lazy(() => import('./pages/mange-admin/Edit'))

// Roles
const ListRoles = lazy(() => import('./pages/mange-roles/List'))
const AddRoles = lazy(() => import('./pages/mange-roles/Add'))
const EditRoles = lazy(() => import('./pages/mange-roles/Edit'))


const ListBadges = lazy(() => import('./pages/badges/List'))
const AddBadges = lazy(() => import('./pages/badges/Add'))
const EditBadges = lazy(() => import('./pages/badges/Edit'))

const Listcharity = lazy(() => import('./pages/charity/List'))
const Addcharity = lazy(() => import('./pages/charity/Add'))
const Editcharity = lazy(() => import('./pages/charity/Edit'))
const AddCharityCauses = lazy(() => import('./pages/charity/AddCharityCauses'))
const PostCharityReport = lazy(() => import('./pages/charity/PostCharityReport'))
const ManualDonationList = lazy(() => import('./pages/charity/ManualDonationList'))
const ManualDonation = lazy(() => import('./pages/charity/ManualDonation'))
const ManualDonationEdit = lazy(() => import('./pages/charity/ManualDonationEdit'))
const ListSiteUser = lazy(() => import('./pages/manage-site-user/List'))
const JusticeCausesDonationReport = lazy(() =>
  import('./pages/reports/JusticeCausesDonationReport'),
)
const DonationReport = lazy(() => import('./pages/reports/DonationReport'))
const UsersReport = lazy(() => import('./pages/reports/UsersReport'))
const UsageAnalytics = lazy(() => import('./pages/reports/UsageAnalytics'))
const VolunteersReport = lazy(() => import('./pages/reports/VolunteersReport'))
const UpcomingEvents = lazy(() => import('./pages/reports/UpcomingEvents'))

const LocalBattleGroundList = lazy(() => import('./pages/battlegrounds/list'))
const LocalBattleGroundAllList = lazy(() => import('./pages/battlegrounds/allList'))
const UpdateCity = lazy(() => import('./pages/battlegrounds/updatecity'))

// Email Templates
const AddTemplate = lazy(() => import('./pages/email-template/Add'))
const ListTemplate = lazy(() => import('./pages/email-template/List'))
const EditTemplate = lazy(() => import('./pages/email-template/Edit'))

// Independent Donation
const IndependentDonationList = lazy(() => import('./pages/independent-charity/ManualDonationList'))
const IndependentDonation = lazy(() => import('./pages/independent-charity/ManualDonation'))
const IndependentDonationEdit = lazy(() => import('./pages/independent-charity/ManualDonationEdit'))

// Independent User Donation
const IndependentDonationUser = lazy(() => import('./pages/independent-charity/ManualDonationUser'))
const IndependentDonationUserList = lazy(() => import('./pages/independent-charity/ManualDonationUserList'))
const IndependentDonationUserEdit = lazy(() => import('./pages/independent-charity/ManualDonationUserEdit'))
// Invite Members
const InviteMembers = lazy(() => import('./pages/invite-members/List'))
const ImportEvent = lazy(() => import('./pages/import/ImportEvent'))
const ImportNews = lazy(() => import('./pages/import/ImportNews'))
const ImportPublications = lazy(() => import("./pages/import/ImportPublications"));
const ImportOrganizations = lazy(() => import("./pages/import/ImportOrganizations"));
const ImportMedia  =lazy(() => import("./pages/import/ImportMedia"));
const ImportFacts = lazy(() => import("./pages/import/ImportFacts"));
const ImportCityEvent = lazy(() => import("./pages/import/ImportCityEvent"));
const ImportCityNews  =lazy(() => import("./pages/import/ImportCityNews"));
const ImportCityOrganization = lazy(() => import("./pages/import/ImportCityOrganization"));
const ImportImages = lazy(() => import("./pages/import/ImportImages"));

const StateList = lazy(() => import('./pages/states/List'))
const UpdateState = lazy(() => import('./pages/states/UpdateState'))
const EditAdminMember = lazy(() => import('./pages/invite-members/Edit'))

// =========================Bulk Upload Edit Route Start=================================
const EditUpcomingEvent = lazy(() => import('./pages/import/import-edit/EditUpcomingEvent'))
const EditFact = lazy(() => import('./pages/import/import-edit/EditFact'))
const EditMedia = lazy(() => import('./pages/import/import-edit/EditMedia'))
const EditNews = lazy(() => import('./pages/import/import-edit/EditNews'))
const EditOrganization = lazy(() => import('./pages/import/import-edit/EditOrganization'))
const EditPublication = lazy(() => import('./pages/import/import-edit/EditPublication'))
const EditCityEvent = lazy(() => import('./pages/import/import-edit/EditCityEvent'))
const EditCityNews = lazy(() => import('./pages/import/import-edit/EditCityNews'))
const EditCityOrganization = lazy(() => import('./pages/import/import-edit/EditCityOrganization'))
// =========================Bulk Upload Edit Route End===================================


function Admin() {
  const page = (component) => {
    return (
      <div
        style={{
          backgroundColor: '#E5E5E5',
          // color: 'white',
          backgroundSize: 'cover',
          minHeight: '100vh',
        }}
      >
        <Header />
        <div className="contentArea">
          <div>
            <SideBar />
          </div>
          <Suspense fallback={<p> Loading...</p>}>
            <div className="fixContent">{component}</div>
          </Suspense>
        </div>
      </div>
    )
  }

  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/dashboard" element={page(<Dashboard />)} />
        <Route exact path="/event" element={page(<AddAdminEvent />)} />
        <Route exact path="/event-list" element={page(<ListAdminEvent />)} />
        <Route exact path="/charity-cause-mapping" element={page(<AddCharityCauses />)} />
        <Route exact path="/charity-list" element={page(<Listcharity />)} />
        <Route exact path="/post-charity-donation" element={page(<PostCharityReport />)} />
        <Route exact path="/manually-added-donation-list" element={page(<ManualDonationList />)} />
        <Route exact path="/add-donation-manual" element={page(<ManualDonation />)} />
        <Route exact path="/edit-donation-manual/:donation_id" element={page(<ManualDonationEdit />)} />

        {/* Independent charity */}
        <Route exact path="/independent-donation-list" element={page(<IndependentDonationList />)} />
        <Route exact path="/add-independent-donation" element={page(<IndependentDonation />)} />
        <Route exact path="/edit-independent-donation/:donation_id" element={page(<IndependentDonationEdit />)} />

        {/* Independent charity for user*/}
        <Route exact path="/add-independent-user-donation" element={page(<IndependentDonationUser />)} />
        <Route exact path="/independent-user-donation-list" element={page(<IndependentDonationUserList />)} />
        <Route exact path="/edit-independent-user-donation/:donation_id" element={page(<IndependentDonationUserEdit />)} />

        <Route exact path="/charity-add" element={page(<Addcharity />)} />
        <Route exact path="/charity-edit/:charity_id" element={page(<Editcharity />)} />
        <Route exact path="/badges-list" element={page(<ListBadges />)} />
        <Route exact path="/badge-add" element={page(<AddBadges />)} />
        <Route exact path="/badge-edit/:badge_id" element={page(<EditBadges />)} />
        <Route exact path="/event-edit/:city_event_id" element={page(<EditAdminEvent />)} />
        <Route exact path="/newsletter-list" element={page(<ListNewsLetter />)} />
        <Route exact path="/newsletter-members" element={page(<ListNewsLetterMembers />)} />
        <Route exact path="/add-newsletter" element={page(<AddNewletter />)} />
        <Route exact path="/newsletter-edit/:news_id" element={page(<EditNewletter />)} />
        <Route exact path="/newsletter-preview/:news_id" element={page(<PreviewNewsletter />)} />
        <Route exact path="/justice-cause-list" element={page(<ListJusticeCause />)} />
        <Route exact path="/justice-cause-add" element={page(<AddJusticeCause />)} />
        <Route exact path="/justice-cause-edit" element={page(<EditJusticeCause />)} />
        {/*Admin User Route*/}
        <Route exact path="/admin-list" element={page(<ListAdmin />)} />
        <Route exact path="/admin-add" element={page(<AddAdmin />)} />
        <Route exact path="/admin-edit" element={page(<EditAdmin />)} />

        {/* Roles */}
        <Route exact path="/roles-list" element={page(<ListRoles />)} />
        <Route exact path="/roles-add" element={page(<AddRoles />)} />
        <Route exact path="/roles-edit" element={page(<EditRoles />)} />

        {/*Site User Route*/}
        <Route exact path="/site-user-list" element={page(<ListSiteUser />)} />
        {/*Donation Report*/}

        {/* Invite Members */}
        <Route exact path="/invite-members" element={page(<InviteMembers />)} />

        {/* Email Template */}
        <Route exact path="/emailtemplate-list" element={page(<ListTemplate />)} />
        <Route exact path="/add-emailtemplate" element={page(<AddTemplate />)} />
        <Route exact path="/edit-emailtemplate/:template_id" element={page(<EditTemplate />)} />

        <Route exact path="/causes-donation-list" element={page(<JusticeCausesDonationReport />)} />
        <Route exact path="/donation-list" element={page(<DonationReport />)} />
        <Route exact path="/charity-donation-report" element={page(<CharityDonationReport />)} />
        <Route exact path="/usage-analytics" element={page(<UsageAnalytics />)} />
        <Route exact path="/volunteers-report" element={page(<VolunteersReport />)} />
        <Route exact path="/upcoming-events" element={page(<UpcomingEvents />)} />
        <Route exact path="/users-report" element={page(<UsersReport />)} />

        <Route exact path="/blog-members" element={page(<ListBlogMembersWordpress />)} />
        {/* <Route exact path="/blog-members" element={page(<ListBlogMembers />)} /> */}
        <Route exact path="/blog-list" element={page(<ListBlog />)} />

        {/* LocalBattleGround */}
        <Route exact path="/localbattleground-list" element={page(<LocalBattleGroundList />)} />
        <Route
          exact
          path="/localbattleground-all-list"
          element={page(<LocalBattleGroundAllList />)}
        />
        <Route exact path="/update-city" element={page(<UpdateCity />)} />

        {/*Bulk Import Causes Element*/}
        <Route exact path="/import-event" element={page(<ImportEvent />)} />
        <Route exact path="/import-news" element={page(<ImportNews />)} />
        <Route exact path="/import-publications" element={page(<ImportPublications />)} />
        <Route exact path="/import-organizations" element={page(<ImportOrganizations />)} />
        <Route exact path="/import-media" element={page(<ImportMedia />)} />
        <Route exact path="/import-facts" element={page(<ImportFacts />)} />

        <Route exact path="/edit-upcoming-event/:event_id" element={page(<EditUpcomingEvent />)} />
        <Route exact path="/edit-fact/:id" element={page(<EditFact />)} />
        <Route exact path="/edit-media/:id" element={page(<EditMedia />)} />
        <Route exact path="/edit-news/:news_id" element={page(<EditNews />)} />
        <Route exact path="/edit-organization/:id" element={page(<EditOrganization />)} />
        <Route exact path="/edit-publication/:id" element={page(<EditPublication />)} />
        {/*Bulk Import City Element*/}
        <Route exact path="/import-city-event" element={page(<ImportCityEvent />)} />
        <Route exact path="/import-city-news" element={page(<ImportCityNews />)} />
        <Route exact path="/import-city-organization" element={page(<ImportCityOrganization />)} />
        <Route exact path="/import-images" element={page(<ImportImages />)} />

        <Route exact path="/edit-city-event/:event_id" element={page(<EditCityEvent />)} />
        <Route exact path="/edit-city-news/:news_id" element={page(<EditCityNews />)} />
        <Route exact path="/edit-city-organization/:id" element={page(<EditCityOrganization />)} />

        {/* States */}
        <Route exact path="/state-list" element={page(<StateList />)} />
        <Route exact path="/update-state/:state_id" element={page(<UpdateState />)} />
        <Route exact path="/admin-member-edit/:member_id" element={page(<EditAdminMember />)} />
      </Routes>
    </div>
  )
}

export default Admin
