import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Button } from 'react-bootstrap'
import { baseURL } from '../config/baseURLPath'
import axios from 'axios'
// import './registration.css';
const AdminLogin = () => {
  const navigate = useNavigate()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState('')

  const handleLogin = (e) => {
    e.preventDefault()
    // navigate('/admin/dashboard')
    // Send a POST request
    if (!username || !password) setMessage('Please enter credentials')
    else {
      axios({
        method: 'post',
        url: baseURL + 'auth/adminSignin',
        data: { admin_email: username, password },
      })
        .then((response) => {
          if (response.data.Success === 200) {
            localStorage.setItem('user_id', response.data.content.admin_id)
            localStorage.setItem('user', JSON.stringify(response.data.content))
            localStorage.setItem('menu', response.data.Menuitems === null ? JSON.stringify([]) : JSON.stringify(response.data.Menuitems.menu_options))
            // localStorage.setItem('menu', response.data.Menuitems === null ? [] : response.data.Menuitems.menu_options)
            localStorage.setItem('lj_token', response.data.accessToken)
            navigate('/admin/dashboard')
          } else {
            setMessage('Invalid Credential')
          }
        })
        .catch((error) => {
          setMessage('Invalid credentials')
        })
    }
  }

  return (
    <>
      <div>
        <Row style={{ margin: 0 }} className="d-flex justify-content-center">
          {/* <Col md={4}></Col> */}
          <Col md={4} className="content-box col-lg-5 col-md-7 col-sm-8 col-11" style={{ marginTop: '20px', padding: 0 }}>
            <Row style={{ margin: 0 }}>
              <Col xs={12}>
                <Row>
                  <Col
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      backgroundColor: '#000000',
                    }}
                  >
                    <img
                      className="mid_pic img-fluid"
                      alt="Resp#000000onsive "
                      src="../assets/Logo.png"
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={12} style={{ padding: '15px' }}>
                <p className="headingText" style={{ textAlign: 'center' }}>
                  ADMIN LOGIN
                </p>
                <Row>
                  <Col md={12}>
                    <div style={{ height: '60px' }}>
                      {message && (
                        <div className="form-group">
                          <div className="alert alert-danger" role="alert">
                            {message}
                          </div>
                        </div>
                      )}
                    </div>
                    <input
                      autoComplete="none"
                      className="registrationFormInputBox"
                      type="email"
                      placeholder="Email *"
                      value={username}
                      onChange={(e) => {
                        setMessage('')
                        setUsername(e.target.value)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <input
                      autoComplete="none"
                      className="registrationFormInputBox"
                      type="password"
                      placeholder="Password *"
                      value={password}
                      onChange={(e) => {
                        setMessage('')
                        setPassword(e.target.value)
                      }}
                    />
                  </Col>
                </Row>
                <Row className="actionButtonDiv">
                  <Button className="acceptButton" onClick={handleLogin}>
                    SUBMIT
                  </Button>
                </Row>
              </Col>
            </Row>
          </Col>
          {/* <Col md={4}></Col> */}
        </Row>
      </div>
    </>
  )
}

export default AdminLogin
