import React, { useEffect, useState } from 'react';
import {gapi} from "gapi-script";
import {clientId} from "../config/payPal";
import {GOOGLE_CLIENT_ID} from "../config/GoogleClientId";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Row, Col, Button, InputGroup } from 'react-bootstrap';
import { baseURL } from '../config/baseURLPath';
import './registration.css';
import axios from 'axios';

import GoogleLogin from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import Analytics from './Analytics';
import queryString from 'query-string';
const publicIp = require('public-ip');
const { detect } = require('detect-browser');
const browser = detect();

const Login = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [searchFor, setSearchFor] = useState('');
  const [event, setEvent] = useState('')
  const AnalyticsRef = React.createRef();




  const handleLogin = (e) => {
    e.preventDefault();
    // Send a POST request
    if (!username || !password) setMessage('Please enter credentials');
    else {
      axios({
        method: 'post',
        url: baseURL + 'auth/signin',
        data: { user_email: username, password },
      })
        .then((response) => {
          if (response.data.Status !== 200) {
            setMessage(response.data.Message);
          } else {
            localStorage.setItem('first_visit', response.data.content.first_time_login_ind)
            localStorage.setItem('user_id', response.data.content.user_id);
            localStorage.setItem('user', JSON.stringify(response.data.content));
            localStorage.setItem('lj_token', response.data.accessToken);
            localStorage.setItem('NeonToken', response.data.NeonToken);
            handleUserLogin(response.data.content.user_id);
            AnalyticsRef.current.handleUserAnalytic();
          }
        })
        .catch((error) => {
          console.log(error);
          setMessage('Invalid credentials');
        });
    }
  };
  useEffect(()=>{
    gapi.load("client:auth2", () => {
      gapi.auth2.init({clientId:GOOGLE_CLIENT_ID})
    })
  },[])
  const [data, setData] = useState({
    browsername: '',
    browserversion: '',
    browseros: '',
    ipaddress: '',
    // login_datetime: new Date(),
  });
  async function getIP() {
    await publicIp
      .v4({ fallbackUrls: ['https://ifconfig.co/ip'] })
      .then((res) => {
        setData((data) => ({ ...data, ipaddress: res }));
      });
  }
  useEffect(() => {
    getIP();
    if (browser) {
      setData((data) => ({
        ...data,
        browserversion: browser.version,
        browsername: browser.name,
        browseros: browser.os,
      }));
    }
  }, []);

  function handleUserLogin(userid) {
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('lj_token') },
    };
    axios
      .post(
        baseURL + 'setUserLogin',
        {
          user_id: userid,
          user_ip: data.ipaddress,
          user_browser: data.browsername,
          user_device: data.browseros,
          // login_datetime: data.login_datetime,
        },
        jwtHeader
      )
      .then(function (response) {
        //console.log(response);
        if (response.data) {
          localStorage.setItem('loginid', response.data.login_id);
          if(event !== ''){
            navigate('/add_event_in_calendar?event_id='+event);
          }else{
            navigate('/dashboard');
          }
          
          // window.location.reload(true);
        }
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  }
  const responseSuccessGoogle = (response) => {
    axios({
      method: 'POST',
      url: baseURL + 'auth/googleLogin',
      data: { tokenId: response.tokenId },
    }).then((res) => {
      console.log('Google login success', res);
      if (res.data.Status === 200) {
        localStorage.setItem('user_id', res.data.Data.user_id);
        localStorage.setItem('user', JSON.stringify(res.data.Data));
        localStorage.setItem('lj_token', res.data.accessToken);
        localStorage.setItem('NeonToken', res.data.NeonToken);
        handleUserLogin(res.data.Data.user_id);
        AnalyticsRef.current.handleUserAnalytic();
      } else {
        setMessage(res.data.Message);
      }
    });
  };

  const responseErrorGoogle = (response) => {
    console.log(response);
  };
  const responseFacebook = (response) => {
    axios({
      method: 'POST',
      url: baseURL + 'auth/facebookLogin',
      data: { accessToken: response.accessToken, userID: response.userID },
    }).then((res) => {
      console.log('Facebook login success', res);
      if (res.data.Success === 200) {
        localStorage.setItem('user_id', res.data.Data.user_id);
        localStorage.setItem('user', JSON.stringify(res.data.Data));
        localStorage.setItem('lj_token', res.data.accessToken);
        localStorage.setItem('NeonToken', res.data.NeonToken);
        handleUserLogin(res.data.Data.user_id);
        AnalyticsRef.current.handleUserAnalytic();
      } else {
        setMessage(res.data.Message);
      }
    });
  };

  useEffect(() => {
    const value = queryString.parse(location.search);
    const search = value.search;
    const event_id = value?.event_id;
    if (localStorage.getItem('user_id') !== null) {
      if (search !== null && search !== undefined) {
        navigate('/donate/userdetails');
      } else {
        navigate('/dashboard');
      }
    } else {
      if (search !== null && search !== undefined) {
        setSearchFor('donate');
      }else if(event_id !== null && event_id !== undefined){
        setEvent(event_id)
      }
    }
    //eslint-disable-next-line
  }, []);

  const navigateToDonate = () => {
    navigate('/donate/userdetails');
  };
  const [verifyEmail, setVerifyEmail] = useState(false);

  const handleEmail = () => {
    axios
      .get(baseURL + 'auth/verifyEmail/' + username)
      .then((res) => {
        console.log(res);
        if (res.data.Status === 200) setVerifyEmail(true);
        else {
          setMessage('User do not exist');
          setUsername('');
        }
      })
      .catch((err) => console.log(err));
  };
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <div>
        <Row style={{ margin: 0 }} className="d-flex justify-content-center">
          {/* <Col className="col-lg-4 col-sm-0 col-md-4"></Col> */}
          <Col className="content-box col-lg-5 col-md-7 col-sm-8 col-11" style={{ marginTop: '20px' }}>
            <Row>
              <Col md={12}>
                <p className="headingText" style={{ textAlign: 'center' }}>
                  Login with your social media account or email address
                </p>
                <Row>
                  <Col
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '10px',
                    }}
                  >
                    {/* <Button className="socialMediaButtons">
                      <img
                        alt="Responsive"
                        src="../assets/vector/Twitter.png"
                      />
                    </Button> */}
                    <FacebookLogin
                      //appId="455531952793027"
                      appId="664140231323013"
                      autoLoad={false}
                      render={(renderProps) => (
                        <Button
                          onClick={renderProps.onClick}
                          className="socialMediaButtons"
                        >
                          <img
                            alt="Responsive "
                            src="../assets/vector/Facebook.png"
                          />
                        </Button>
                      )}
                      callback={responseFacebook}
                    />
                    <GoogleLogin
                      clientId={GOOGLE_CLIENT_ID}
                      render={(renderProps) => (
                        <Button
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          className="socialMediaButtons"
                        >
                          <img
                            alt="Responsive "
                            src="../assets/vector/Google.png"
                          />
                        </Button>
                      )}
                      buttonText="Login"
                      onSuccess={responseSuccessGoogle}
                      onFailure={responseErrorGoogle}
                      cookiePolicy={'single_host_origin'}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="dividerDiv">
                    <div className="dividerLine"></div>
                    <div className="dividerBox">
                      <p className="dividerBoxText">OR</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div style={{ height: '60px' }}>
                      {message && (
                        <div className="form-group">
                          <div className="alert alert-danger" role="alert">
                            {message}
                          </div>
                        </div>
                      )}
                    </div>
                    <input
                      autoComplete="none"
                      className="registrationFormInputBox"
                      type="email"
                      placeholder="Email *"
                      value={username}
                      onChange={(e) => {
                        setMessage('');
                        setUsername(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                {verifyEmail ? (
                  <Row>
                    <Col md={12}>
                      <InputGroup style={{ width: '100%' }}>
                        <div style={{ width: '85%' }}>
                          <input
                            autoComplete="none"
                            className="form control registrationFormInputBox"
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Password *"
                            value={password}
                            onChange={(e) => {
                              setMessage('');
                              setPassword(e.target.value);
                            }}
                          />
                        </div>
                        <InputGroup.Text
                          style={{ marginTop: '10px', width: '15%' }}
                        >
                          <Link
                            to="#"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <i
                              className={
                                showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'
                              }
                            ></i>
                          </Link>
                        </InputGroup.Text>
                      </InputGroup>
                    </Col>
                  </Row>
                ) : null}
                {verifyEmail ? (
                  <Row className="actionButtonDiv">
                    <Button
                      className="acceptButton"
                      onClick={handleLogin}
                      style={{ width: '220px' }}
                    >
                      LOGIN
                    </Button>
                    <Analytics ref={AnalyticsRef} ip={data.ipaddress} />
                  </Row>
                ) : (
                  <Row className="actionButtonDiv">
                    <Button className="acceptButton" onClick={handleEmail}>
                      SUBMIT
                    </Button>
                    <Analytics ref={AnalyticsRef} ip={data.ipaddress} />
                  </Row>
                )}
                {searchFor !== '' ? (
                  <Row
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '15px',
                    }}
                  >
                    <Button
                      className="acceptButton"
                      onClick={navigateToDonate}
                      style={{ width: '220px' }}
                    >
                      DONATE WITHOUT LOGIN
                    </Button>
                  </Row>
                ) : null}
                <Row>
                  <Col md={12} style={{ textAlign: 'center' }}>
                    <p>
                      <span>
                        <Link to="/forgot-password" className="links">
                          {' '}
                          Forgot Password?{' '}
                        </Link>
                      </span>
                    </p>
                    <p className="conditionText">
                      <span>Don't have an account?</span>
                      <span>
                        <Link
                          to="/become_a_member/member_pledge"
                          className="links"
                        >
                          {' '}
                          Become a member
                        </Link>
                      </span>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {/* <Col className="col-lg-4 col-sm-0 col-md-4"></Col> */}
        </Row>
      </div>
    </>
  );
};

export default Login;
