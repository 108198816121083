import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import './commoncss.css';
import seal from '../../assets/images/seal.png';

const Footer = () => {
  const location = useLocation();
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('user_id'));
  return (
    <div className="fixFooter">
      <Row style={{ marginLeft: "5vw", marginRight:"5vw" }}>
        <Col className="leftMenuSection footer-logo-bar">
          <a href="https://www.lovejustice.com/">
            <img
              className="mid_pic img-fluid"
              alt="Responsive "
              src="https://love-justice-images.s3.us-east-2.amazonaws.com/website_footer.png"
              style={{maxHeight:"96px"}}
            />
          </a>
        </Col>
       
      </Row>
      <Row style={{ marginLeft: "5vw", marginRight:"5vw" }}>
        <Col md={3}>
          <p className="footerCategory" style={{height:"50px"}}>Site Links</p>
          <a href="https://www.lovejustice.com/" className="footerLink">
            HOME
          </a>
          <a href="https://www.lovejustice.com/about/" className="footerLink">
            ABOUT
          </a>
          {/* {loggedIn ? null : (
          <Link
            className={
              location.pathname === '/become_a_member/member_pledge'
                ? 'activeFooterLink'
                : 'footerLink'
            }
            to="/become_a_member/member_pledge"
          >
            BECOME A MEMBER
          </Link>
          )} */}
          {loggedIn ? (
          <Link
            className={
              location.pathname === '/dashboard'
                ? 'activeFooterLink'
                : 'footerLink'
            }
            to="/"
          >
            MY DASHBOARD
          </Link>) : null }
        </Col>
        <Col md={3}>
          <p className="footerCategory" style={{height:"50px"}}></p>
          <a className="footerLink" href="https://www.lovejustice.com/social-justice-causes">
            SOCIAL JUSTICE CAUSES
          </a>
          <a className="footerLink" href="https://www.lovejustice.com/local-city-info">
            LOCAL CITY INFO
          </a>
          <a className="footerLink" href="https://www.lovejustice.com/blog/">
            BLOG
          </a>
        </Col>
        <Col md={3}>
          <p className="footerCategory" style={{height:"50px"}}>Quick Links</p>
          <a
            className="footerLink"
            href="https://www.lovejustice.com/contact/"
          >
            CONTACT
          </a>
          <Link
            to="/terms-and-conditions"
            className={
              location.pathname === '/terms-and-conditions'
                ? 'activeFooterLink'
                : 'footerLink'
            }
          >
            TERMS OF SERVICE
          </Link>
          <Link
            to="/donationlist"
            className={
              location.pathname === '/donationlist'
                ? 'activeFooterLink'
                : 'footerLink'
            }
          >
            TOTAL INCOME AND DISTRIBUTION TO CAUSES
          </Link>
        </Col>
        <Col md={3}>
          <p className="footerCategory">Accreditations and Awards</p>
          <img
            className="logo-icon"
            alt="Responsive"
            src={seal}
            width="150px"
          />
          <p className="footerCategory" style={{fontSize:"16px"}}>GuideStar Platinum Rated Charity</p>
          <a href="https://www.lovejustice.com/about/" className="footerLink">LoveJustice is a 501(c)3 Non-Profit Organization.</a> 
          <p className="footerLink" style={{cursor:"auto"}}>Federal Tax ID 85-3861711.</p>
        </Col>
      </Row>
      <Row style={{ marginLeft: "5vw", marginRight:"5vw" }}>
        <Col>
          <p style={{ color: 'white', fontSize: '12px'}}>
            © 2022, LoveJustice. All Rights Reserved.
          </p> 
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
