import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';

import Header from './registration/common/Header';
import DashboardHeader from './registration/common/DashboardHeader';
import Footer from './registration/common/Footer';
import Login from './registration/login';
import AddEventInCalendar from './registration/addEventInCalendar';
import VerifiedSuccessfully from './registration/verifiedSuccessfully';
import Logout from './registration/logout';
import Admin from './admin';
import { Spinner } from 'react-bootstrap';
import NeonPayment from './registration/common/PaymentIntegration';

const Registration = lazy(() => import('./registration'));
const TnC = lazy(() => import('./registration/tnc'));
const PrivacyPolicy = lazy(() => import('./registration/privacy'));

const Dashboard = lazy(() => import('./secured/Dashboard'));
const AcceptInvitation = lazy(() => import('./secured/AcceptInvitation'));
const AddTeamEvent = lazy(() => import('./secured/AddTeamEvent'));
const DonationList = lazy(() => import('./secured/DonationList'));
const Donate = lazy(() => import('./secured/Donate'));
const PublicProfile = lazy(() => import('./secured/PublicProfile'));
const MyProfile = lazy(() => import('./secured/MyProfile'));
const Notifications = lazy(() => import('./secured/Notifications'));
const MyConnections = lazy(() => import('./secured/MyConnections'));
const VolunteerHours = lazy(() => import('./secured/VolunteerHours'));
const Settings = lazy(() => import('./secured/Settings'));
const SearchMembers = lazy(() => import('./secured/SearchMembers'));
const SearchTeamMembers = lazy(() => import('./secured/SearchTeamMembers'));
const AllEvents = lazy(() => import('./secured/AllEvents'));
const AddTeamInvite = lazy(() => import('./secured/AddTeamInvite'));

const ViewReceipt = lazy(() => import('./secured/ViewReceipt'));
const DonationComplete = lazy(() =>
  import('./registration/donation/DonationComplete')
);
const Step1 = lazy(() => import('./registration/donation/step1'));
const Step2 = lazy(() => import('./registration/donation/step2'));
const Step3 = lazy(() => import('./registration/donation/step3'));
const Step4 = lazy(() => import('./registration/donation/step4'));
const ForgotPassword = lazy(() => import('./registration/forgotPassword'));
const UpdatePassword = lazy(() => import('./registration/updatePassword'));
const Unsubscribe = lazy(() => import('./open/unsubscribe'));
const Newsletter = lazy(() => import('./open/newsletter'));  

function App() {
  const userdata = JSON.parse(localStorage.getItem('user'));
  const page = (component) => {
    return (
      <div
        style={{
          background: `url(../assets/Union-2.svg) no-repeat center center`,
          color: 'white',
          backgroundSize: 'cover',
        }}
      >
        <Header />
        {component}
        <Footer />
      </div>
    );
  };

  const afterlogin = (component) => {
    return (
      <div style={{ backgroundColor: '#E5E5E5' }}>
        <DashboardHeader />
        {component}
        <Footer />
      </div>
    );
  };

  const openpage = (component) => {
    return (
      <div
        style={{
          background: `url(../assets/Union-2.svg) no-repeat center center`,
          color: 'white',
          backgroundSize: 'cover',
        }}
      >
        <Header />
        {component}
        <Footer />
      </div>
    );
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Suspense
          fallback={
            <Spinner
              animation="border"
              role="status"
              style={{
                top: '50%',
                left: '50%',
                display: 'block',
                position: 'fixed',
              }}
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          }
        >
          <Routes>
            <Route exact path="/become_a_member/*" element={<Registration />} />
            <Route exact path="/" element={page(<Login />)} />
            <Route exact path="/verifyEmailAddress/:id" element={page(<VerifiedSuccessfully />)} />
            <Route exact path="/add_event_in_calendar" element={page(<AddEventInCalendar />)} />

            <Route exact path="/payment-integration" element={page(<NeonPayment />)} />
            
            <Route exact path="/logout" element={page(<Logout />)} />
            <Route
              exact
              path="/forgot-password"
              element={page(<ForgotPassword />)}
            />
            <Route
              exact
              path="/updatepass"
              element={page(<UpdatePassword />)}
            />
            <Route exact path="/terms-and-conditions" element={page(<TnC />)} />
            <Route
              exact
              path="/privacy-policy"
              element={page(<PrivacyPolicy />)}
            />
            <Route
              exact
              path="/dashboard"
              element={afterlogin(<Dashboard />)}
            />
            <Route
              exact
              path="/view-receipt"
              element={afterlogin(<ViewReceipt />)}
            />
            <Route
              exact
              path="/searchMembers"
              element={afterlogin(<SearchMembers />)}
            />
          
            <Route
              exact
              path="/searchTeamMembers"
              element={afterlogin(<SearchTeamMembers />)}
            />
            <Route exact path="/events" element={afterlogin(<AllEvents />)} />
            <Route exact path="/teaminvite/:event_id" element={afterlogin(<AddTeamInvite />)} />
           

            <Route
              exact
              path="/addteamevent"
              element={afterlogin(<AddTeamEvent />)}
            />
            <Route
              exact
              path="/donationlist"
              element={afterlogin(<DonationList />)}
            />
            <Route exact path="/donate" element={afterlogin(<Donate />)} />

            <Route
              exact
              path="/donate/user-details"
              element={afterlogin(<Step1 />)}
            />
            <Route
              exact
              path="/donate/add-donation-causes"
              element={afterlogin(<Step2 />)}
            />
            <Route
              exact
              path="/donate/amount-info"
              element={afterlogin(<Step3 />)}
            />
            <Route
              exact
              path="/donate/payment-summary"
              element={afterlogin(<Step4 />)}
            />
           <Route exact path="/accept-invitation/:sendBy" element={afterlogin(<AcceptInvitation />)} />
            <Route
              exact
              path="/success"
              element={afterlogin(<DonationComplete />)}
            />
            <Route
              exact
              path="/myprofile"
              element={afterlogin(<MyProfile />)}
            />
            <Route
              exact
              path="/profile"
              element={afterlogin(<PublicProfile />)}
            />
            <Route exact path="/settings" element={afterlogin(<Settings />)} />
            <Route
              exact
              path="/notifications"
              element={afterlogin(<Notifications />)}
            />
            <Route
              exact
              path="/connections"
              element={afterlogin(<MyConnections />)}
            />
            {userdata  !== null ?(
              <Route
              exact
              path="/volunteer"
              element={afterlogin(<VolunteerHours />)}
            />
            ):(<Route
              exact
              path="/volunteer"
              element={page(<VolunteerHours />)}
            />)}
            <Route
              exact
              path="/newsletter/:url"
              element={openpage(<Newsletter />)}
            />
            <Route
              exact
              path="/unsubscribe"
              element={<Unsubscribe />}
            />
            <Route exact path="/admin/*" element={<Admin />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
