import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Offcanvas } from 'react-bootstrap';
import './commoncss.css';
export default function Sidebar() {
  const location = useLocation();

  const [cities] = useState([
    { city_id: 1, city_name: 'ALBUQUERQUE'},
    { city_id: 2, city_name: 'ATLANTA'},
    { city_id: 3, city_name: 'AUSTIN'},
    { city_id: 4, city_name: 'BALTIMORE'},
    { city_id: 5, city_name: 'BOSTON'},
    { city_id: 6, city_name: 'CHICAGO'},
    { city_id: 7, city_name: 'COLORADO SPRINGS'},
    { city_id: 8, city_name: 'DALLAS'},
    { city_id: 9, city_name: 'DENVER'},
    { city_id: 10, city_name: 'DETROIT'},
    { city_id: 11, city_name: 'FORT WORTH'},
    { city_id: 12, city_name: 'HOUSTON'},
    { city_id: 13, city_name: 'INDIANAPOLIS'},
    { city_id: 14, city_name: 'JACKSONVILLE'},
    { city_id: 15, city_name: 'KANSAS CITY'},
    { city_id: 16, city_name: 'LAS VEGAS'},
    { city_id: 17, city_name: 'LOS ANGELES'},
    { city_id: 18, city_name: 'LOUISVILLE'},
    { city_id: 19, city_name: 'MEMPHIS'},
    { city_id: 20, city_name: 'MIAMI'},
    { city_id: 21, city_name: 'MILWAUKEE'},
    { city_id: 22, city_name: 'MINNEAPOLIS'},
    { city_id: 23, city_name: 'NASHVILLE'},
    { city_id: 24, city_name: 'NEW ORLEANS'},
    { city_id: 25, city_name: 'NEW YORK'},
    { city_id: 26, city_name: 'OAKLAND'},
    { city_id: 27, city_name: 'OKLAHOMA CITY'},
    { city_id: 28, city_name: 'PHILADELPHIA'},
    { city_id: 29, city_name: 'PHOENIX'},
    { city_id: 30, city_name: 'PORTLAND'},
    { city_id: 31, city_name: 'RALEIGH'},
    { city_id: 32, city_name: 'SACRAMENTO'},
    { city_id: 33, city_name: 'SAN ANTONIO'},
    { city_id: 34, city_name: 'SAN DIEGO'},
    { city_id: 35, city_name: 'SAN FRANCISCO'},
    { city_id: 36, city_name: 'SEATTLE'},
    { city_id: 37, city_name: 'TAMPA'},
    { city_id: 38, city_name: 'TUCSON'},
    { city_id: 39, city_name: 'WASHINGTON'},
  ]);  

  const [causes] = useState([
    { id: 1, name: 'AGE DISCRIMINATION', url: 'AGE DISCRIMINATION'},
    { id: 2, name: 'CULTS & CONSPIRACY THEORIES', url: 'CULTS CONSPIRACY THEORIES'},
    { id: 3, name: 'EDUCATION', url: 'EDUCATION'},
    { id: 4, name: 'ENVIRONMENT', url: 'ENVIRONMENT'},
    { id: 5, name: 'GUN VIOLENCE', url: 'GUN VIOLENCE'},
    { id: 6, name: 'HEALTH & WELLNESS', url: 'HEALTH WELLNESS'},
    { id: 7, name: 'HOUSING/HOMELESSNESS', url: 'HOUSING HOMELESSNESS'},
    { id: 8, name: 'HUMAN TRAFFICKING', url: 'HUMAN TRAFFICKING'},
    { id: 9, name: 'HUNGER', url: 'HUNGER'},
    { id: 10, name: 'IMMIGRATION', url: 'IMMIGRATION'},
    { id: 11, name: 'LGBTQ ISSUES/RIGHTS', url: 'LGBT ISSUES RIGHTS'},
    { id: 12, name: 'LIVING WAGE', url: 'LIVING WAGE'},
    { id: 13, name: 'MINORITY ISSUES/RIGHTS', url: 'MINORITY ISSUES RIGHTS'},
    { id: 14, name: 'POLICE ISSUES', url: 'POLICE REFORM'},
    { id: 15, name: 'POVERTY', url: 'POVERTY'},
    { id: 16, name: 'PRISON REFORM', url: 'PRISON REFORM'},
    { id: 17, name: 'VETERAN’S ISSUES', url: 'VETERANS ISSUES'},
    { id: 18, name: 'VOTING RIGHTS', url: 'VOTING RIGHTS'},
    { id: 19, name: 'WAR/GLOBAL CONFLICT', url: 'WAR GLOBAL CONFLICT'},
    { id: 20, name: 'WOMEN’S ISSUES/RIGHTS', url: 'WOMENS ISSUES RIGHTS'},
  ]);



  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('user_id'));
  useEffect(() => {
    if (location.pathname === '/') setLoggedIn(false);
  }, [location.pathname]);

  const changeCityToURL = (city) =>{
    let url_part = city.toLowerCase().split(' ').join('-');

    let full_url = "https://www.lovejustice.com/"+url_part+"/";

    return full_url;
  }

  const changeIssueToURL = (issue) =>{
    let url_part = issue.toLowerCase().split(' ').join('-');

    let full_url = "https://www.lovejustice.com/"+url_part+"/";

    return full_url;
  }


  return (
    <Navbar expand={false}>
      <Navbar.Toggle aria-controls="offcanvasNavbar">
        <img
          className="logo-icon menu-icon"
          alt="Responsive "
          src="../assets/Icon/Menu1.svg"
        />
      </Navbar.Toggle>
      <Navbar.Offcanvas
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
        placement="end"
        className="p-4 OffCanvas sidebar-css"
        style={{ width: '340px', backgroundColor: 'whitesmoke' }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title id="offcanvasNavbarLabel">
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="justify-content-end flex-grow-1">
            <Nav.Link href="https://www.lovejustice.com/">HOME</Nav.Link>
            <Nav.Link href="https://www.lovejustice.com/about">ABOUT</Nav.Link>
            <NavDropdown
              title="SOCIAL JUSTICE CAUSES"
              id="offcanvasNavbarDropdown1"
              style={{maxHeight: '400px', overflow: 'overlay'}}
            >
              {causes?.map((cause) => (
                <NavDropdown.Item href={changeIssueToURL(cause.url)} key={cause.id} className='overflow-auto' style={{fontSize: '10px'}}>
                  {cause.name.toUpperCase()}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
            <NavDropdown title="LOCAL CITY INFO" id="offcanvasNavbarDropdown2" style={{maxHeight: '400px', overflow: 'overlay'}}>
              {cities?.map((city) => (
                <NavDropdown.Item href={changeCityToURL(city.city_name)} key={city.city_id} className='overflow-auto' style={{fontSize: '10px'}}>
                  {city.city_name.toUpperCase()}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
            {loggedIn ? null : 
            <Nav.Link
              href="/become_a_member/member_pledge"
              className={
                `${location.pathname === '/become_a_member/member_pledge'
                  ? 'active1'
                  : ''}`
              }
            >
              BECOME A MEMBER
            </Nav.Link> }
            <Nav.Link
              href="/"
              className={
                // location.pathname === '/' ||
                `${location.pathname === '/dashboard' ? 'active1' : ''}`
              }
            >
              MY DASHBOARD
            </Nav.Link>
            <Nav.Link href="https://www.lovejustice.com/blog/">BLOG</Nav.Link>
            
            <Nav.Link href="https://www.lovejustice.com/contact/">
              CONTACT
            </Nav.Link>
            {loggedIn ? <Nav.Link href="/logout">LOGOUT</Nav.Link> : null}
          </Nav>
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </Navbar>
  );
}
