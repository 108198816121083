import React, {useState, useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'
// import { Row, Col, Container } from 'react-bootstrap'
import './admincss.css'
import {ProSidebar, Menu, MenuItem, SubMenu} from 'react-pro-sidebar'
import 'react-pro-sidebar/dist/css/styles.css'

const SideBar = () => {
    const navigate = useNavigate();
    const [menus, setMenus] = useState([])
    useEffect(async () => {
        let menu_items = localStorage.getItem('menu');
        let menus = await JSON.parse(menu_items);
        if (menus.length === 0) {
            navigate('/admin')
        } else {
            await findSelectedMenu(menus)
        }
    }, [])

    const findSelectedMenu = (menus) => {
        let selected_menus = [];
        if (menus !== null) {
            menus.map((item) => {
                selected_menus.push(item.menu_id)
            })
        }
        setMenus(selected_menus)
    }

    return (
        <ProSidebar>
            <Menu iconShape="square">
                {menus.includes(1) ?
                    <MenuItem icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                        <Link className="menuLink" to="/admin/dashboard"/>
                        Dashboard
                    </MenuItem> : null}
                {menus.includes(2) ?
                    <SubMenu title="Master" icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                        <MenuItem icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                            <Link className="menuLink" to="/admin/badges-list"/>
                            Badges
                        </MenuItem>
                        <MenuItem icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                            <Link className="menuLink" to="/admin/justice-cause-list"/>
                            Justice Cause
                        </MenuItem>
                        <MenuItem icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                            <Link className="menuLink" to="/admin/state-list"/>
                            States
                        </MenuItem>
                        <SubMenu title="Battlegrounds" icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                            <MenuItem>
                                <Link className="menuLink" to="/admin/localbattleground-all-list"/>
                                Cities
                            </MenuItem>
                            <MenuItem>
                                <Link className="menuLink" to="/admin/localbattleground-list"/>
                                Local Battleground
                            </MenuItem>
                        </SubMenu>
                    </SubMenu> : null}
                {menus.includes(3) ?
                    <SubMenu title="Organizations" icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                        <MenuItem icon="">
                            <Link className="menuLink" to="/admin/charity-list"/>
                            Manage Orgs
                        </MenuItem>
                        <MenuItem icon="">
                            <Link className="menuLink" to="/admin/charity-cause-mapping"/>
                            Orgs Cause Mapping
                        </MenuItem>
                        {/* <MenuItem icon="">
            <Link className="menuLink" to="/admin/post-charity-donation" />
           Donation Reports
          </MenuItem>
          <MenuItem icon="">
            <Link className="menuLink" to="/admin/manually-added-donation-list" />
            Manual Donation List
          </MenuItem>
          <MenuItem icon="">
            <Link className="menuLink" to="/admin/add-donation-manual" />
            Add Donation Manually
          </MenuItem> */}
                    </SubMenu> : null}
                {menus.includes(4) ?
                    <SubMenu title="Independent Donations" icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                        <MenuItem icon="">
                            <Link className="menuLink" to="/admin/add-independent-donation"/>
                            Add (Made by LJ)
                        </MenuItem>
                        <MenuItem icon="">
                            <Link className="menuLink" to="/admin/independent-donation-list"/>
                            List (Made by LJ)
                        </MenuItem>
                        <MenuItem icon="">
                            <Link className="menuLink" to="/admin/add-independent-user-donation"/>
                            Add (Made by Users)
                        </MenuItem>
                        <MenuItem icon="">
                            <Link className="menuLink" to="/admin/independent-user-donation-list"/>
                            List (Made by Users)
                        </MenuItem>
                    </SubMenu> : null}
                {menus.includes(5) ?
                    <MenuItem icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                        <Link className="menuLink" to="/admin/event-list"/>
                        Events Calendar
                    </MenuItem> : null}
                {menus.includes(6) ?
                    <SubMenu title="Newsletters" icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                        <MenuItem>
                            <Link className="menuLink" to="/admin/add-newsletter"/>
                            Add Newsletter
                        </MenuItem>
                        <MenuItem>
                            <Link className="menuLink" to="/admin/newsletter-list"/>
                            View Newsletters
                        </MenuItem>
                        <MenuItem>
                            <Link className="menuLink" to="/admin/newsletter-members"/>
                            Newsletters Members
                        </MenuItem>
                    </SubMenu> : null}
                {menus.includes(7) ?
                    <SubMenu title="Blogs" icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                        <MenuItem>
                            <Link className="menuLink" to="/admin/blog-list"/>
                            Blog List
                        </MenuItem>
                        {/* <MenuItem>
            <Link className="menuLink" to="/admin/blog-members" />
              Members (from Dashboard)
          </MenuItem> */}
                        <MenuItem>
                            <Link className="menuLink" to="/admin/blog-members"/>
                            Members
                        </MenuItem>
                    </SubMenu> : null}
                {menus.includes(8) ?
                    <SubMenu title="Email Templates" icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                        <MenuItem>
                            <Link className="menuLink" to="/admin/add-emailtemplate"/>
                            Add Template
                        </MenuItem>
                        <MenuItem>
                            <Link className="menuLink" to="/admin/emailtemplate-list"/>
                            Template List
                        </MenuItem>
                    </SubMenu> : null}
                {menus.includes(9) ?
                    <SubMenu title="User/Roles" icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                        <MenuItem>
                            <Link className="menuLink" to="/admin/roles-list"/>
                            Roles
                        </MenuItem>
                        <MenuItem>
                            <Link className="menuLink" to="/admin/admin-list"/>
                            Manage Admin User
                        </MenuItem>
                        <MenuItem>
                            <Link className="menuLink" to="/admin/site-user-list"/>
                            Manage Site User
                        </MenuItem>
                    </SubMenu>
                    : null}
                {menus.includes(10) ?
                    <SubMenu title="Reports" icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                        <MenuItem>
                            <Link className="menuLink" to="/admin/donation-list"/>
                            Donation Report
                        </MenuItem>

                        <MenuItem>
                            <Link className="menuLink" to="/admin/users-report"/>
                            Members Report
                        </MenuItem>
                        <MenuItem>
                            <Link className="menuLink" to="/admin/usage-analytics"/>
                            Usage Report
                        </MenuItem>
                        <MenuItem>
                            <Link className="menuLink" to="/admin/upcoming-events"/>
                            Upcoming events
                        </MenuItem>
                        <MenuItem icon="">
                            <Link className="menuLink" to="/admin/volunteers-report"/>
                            Volunteers Report
                        </MenuItem>
                        <MenuItem>
                            <Link className="menuLink" to="/admin/causes-donation-list"/>
                            Justice Causes <br/> Donation Report
                        </MenuItem>
                        <MenuItem>
                            <Link className="menuLink" to="/admin/charity-donation-report"/>
                            Designated Donation <br/>
                            for Charities
                        </MenuItem>
                    </SubMenu> : null}
                {menus.includes(11) ?
                    <SubMenu title="Bulk Uploads" icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                        <MenuItem icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                            <Link className="menuLink" to="/admin/invite-members"/>
                            Invite Members
                        </MenuItem>
                        <MenuItem icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                            <Link className="menuLink" to="/admin/import-images"/>
                            Import Images
                        </MenuItem>
                        <SubMenu title="Bulk Uploads Causes" icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                        <MenuItem icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                            <Link className="menuLink" to="/admin/import-event"/>
                            Import Event
                        </MenuItem>
                        <MenuItem icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                            <Link className="menuLink" to="/admin/import-news"/>
                            Import News
                        </MenuItem>
                        <MenuItem icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                            <Link className="menuLink" to="/admin/import-publications"/>
                            Import Publications
                        </MenuItem>
                        <MenuItem icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                            <Link className="menuLink" to="/admin/import-organizations"/>
                            Import Organizations
                        </MenuItem>
                        <MenuItem icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                            <Link className="menuLink" to="/admin/import-media"/>
                            Import Media
                        </MenuItem>
                        <MenuItem icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                            <Link className="menuLink" to="/admin/import-facts"/>
                            Import Facts
                        </MenuItem>
                        </SubMenu>
                            <SubMenu title="Bulk Uploads City" icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                        <MenuItem icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                            <Link className="menuLink" to="/admin/import-city-organization"/>
                            Import City Organizations
                        </MenuItem>
                        <MenuItem icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                            <Link className="menuLink" to="/admin/import-city-event"/>
                            Import City Event
                        </MenuItem>
                        <MenuItem icon="" style={{fontSize: '16px', marginTop: "15px"}}>
                            <Link className="menuLink" to="/admin/import-city-news"/>
                            Import City News
                        </MenuItem>
                    </SubMenu>
                    </SubMenu> : null}
            </Menu>
        </ProSidebar>
    )
}

export default SideBar
