import { Component } from 'react';
import nextId from 'react-id-generator';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import { baseURL } from '../config/baseURLPath';
import publicIp from 'public-ip';
const { detect } = require('detect-browser');
const browser = detect();

export default class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      browsername: '',
      browseros: '',
      browserversion: '',
      ipadd: '',
      session_id: nextId(),
      url_visited: '',
      traffic_source: '',
    };
    this.handleUserAnalytic = this.handleUserAnalytic.bind(this);
  }

  componentDidMount() {}
  async getIP() {
    await publicIp
      .v4({ fallbackUrls: ['https://ifconfig.co/ip'] })
      .then((res) => {
        this.setState({ ipadd: res });
      });
  }
  handleUserAnalytic() {
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('lj_token') },
    };
    let objectThis = this;
    if (browser) {
      objectThis.setState({ browsername: browser.name });
      objectThis.setState({ browserversion: browser.version });
      objectThis.setState({ browseros: browser.os });
    }
    (async () => {
      if (this.props.ip !== '') {
        objectThis.setState({ ipadd: this.props.ip });
      } else {
        objectThis.getIP();
      }
    })();
    objectThis.setState({ url_visited: window.location.href });
    if (isMobile) {
      objectThis.state.traffic_source = 'Mobile';
      objectThis.setState({ traffic_source: objectThis.state.traffic_source });
    } else {
      objectThis.state.traffic_source = 'Web';
      objectThis.setState({ traffic_source: objectThis.state.traffic_source });
    }
    objectThis.userData = JSON.parse(localStorage.getItem('user'));
    // console.log(objectThis.state);
    axios
      .post(
        baseURL + 'setUserAnalytics',
        {
          user_id: objectThis.userData.user_id,
          session_id: objectThis.state.session_id,
          user_browser: objectThis.state.browsername,
          user_device: objectThis.state.browseros,
          user_ip: objectThis.state.ipadd,
          login_datetime: objectThis.state.login_datetime,
          traffic_source: objectThis.state.traffic_source,
          url_visited: objectThis.state.url_visited,
        },
        jwtHeader
      )
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log('error1' + error);
      });
  }

  render() {
    return null;
  }
}
