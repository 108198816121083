import React, { useEffect } from 'react';
import {useNavigate } from 'react-router-dom';
import {  } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';


const NeonPayment = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    //eslint-disable-next-line
  }, []);

  
  return (
    <>
      <div>
        <Row style={{ margin: 0 }}>
        <div className="contentHeadingDiv" style={{ marginTop: '15px' }}>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
              >
                <img
                  style={{ marginTop: '-5px', marginRight: '10px' }}
                  src="../assets/vector/go_back.svg"
                  alt="back"
                ></img>

              <span
                className="contentHeadingMainText"
                style={{ marginRight: '10px' }}
              >
                Go Back
              </span>
              </a>
            </div>
          <Col md={4}></Col>
          <Col md={4} className="content-box" style={{ marginTop: '20px' }}>
            <Row>
              <Col md={12}>
                <form id="paymentForm" action="#" method="POST">
                <h3 style={{color:'black',textAlign:'center'}}>Please fill card details</h3>
                <div id="cardFields"></div>
                <input type="hidden" id="token" value="" />
                <input id="paymentSubmit" type="submit" value="Process Payment" />
                {/* <button id="swipe" type="button" name="button">Swipe</button> */}
                </form>
              </Col>
            </Row>
          </Col>
          <Col md={4}></Col>
        </Row>
      </div>
    </>
  );
};

export default NeonPayment;
